<template>
  <section
    v-if="collection_banners.length"
    class="product-collection-banner"
    :class="{
      'product-collection-banner--admin': admin_imported && show_mobile
    }"
  >
    <h2 v-if="correct_translations.product_categories_title">
      {{ correct_translations.product_categories_title }}
    </h2>
    <div class="product-collection-banner__wrapper">
      <div v-if="show_empty_admin_preview" class="product-collection-banner__demo-message">
        <p class="md-title">{{ $translate("collection_banners.demo_message") }}</p>
      </div>
      <carousel 
        :autoplay="false"
        :paginationEnabled="true"
        :navigationEnabled="false"
        :perPageCustom="decide_items_per_slide"
        navigationNextLabel=" "
        navigationPrevLabel=" "
      >
        <slide v-if="loading" class="element-loading product-collection-banner__item"/>
        <slide v-if="loading" class="element-loading product-collection-banner__item"/>
        <slide
          v-else
          v-for="({ thumb_image, mobile_image, description, category, sub_category, img, button_text, direct_route, title }, index) in collection_banners"
          :key="`product-collection-${index}`"
          class="product-collection-banner__item"
        >
          <router-link
            :to="get_banner_route(direct_route, sub_category, category)"
            itemprop="link"
            :event="admin_imported ? '' : 'click'"
            :target="direct_route ? '_blank' : '_self'"
            @click.native="(e) => handle_link_click(e, category, sub_category)"
          >
            <div class="product-collection-banner__background" :class="{ 'product-collection-banner__background--admin': admin_imported && show_mobile }">
              <v-lazy-image
                data-cy="category-banner-image"
                :src="get_correct_image(img, mobile_image || thumb_image)"
                :src-placeholder="mobile_image || thumb_image || default_image_src"
                alt="category image"
              />
            </div>
            <div class="product-collection-banner__gradient" :class="{ 'product-collection-banner__gradient--admin': admin_imported && show_mobile }"/>
            <div class="product-collection-banner__content"  :class="{ 'product-collection-banner__content--admin': admin_imported && show_mobile }" @click="$emit('handle_click')">
              <h3 v-if="title" data-cy="product-collection-banner-title" class="product-collection-banner__title" v-html="title" />
              <p data-cy="product-collection-banner-description" class="product-collection-banner__description" v-html="description" />
              <router-link
                data-cy="banner-category-link"
                :to="get_banner_route(direct_route, sub_category, category)"
                :event="admin_imported ? '' : 'click'"
                itemprop="link"
                class="product-collection-banner__link"
                :target="direct_route ? '_blank' : '_self'"
                @click.native="(e) => handle_link_click(e, category, sub_category)"
              >
                {{ button_text ? "" : correct_translations.product_categories_explore }} {{ button_text || transform_to_normal_text(sub_category ? sub_category : category) }}
                <arrow :fill="project_styles.colors.item_name_color" />
              </router-link>
            </div>
          </router-link>
        </slide>
      </carousel>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import VLazyImage from "v-lazy-image/v2/v-lazy-image.js"
import { Carousel, Slide } from "../../../../Shared/components/slider/index"
import arrow from "../../../../Shared/components/icon_components/arrow"
import { CONTENT_STORE, deleted, CONFIGURATION_STORE, PRODUCTS_STORE } from "../../../../Admin/constants/others_constants"
import { CLIENT_STORE } from "../../../constants/other"
import { default_image_src, SHARED_STORE } from "../../../../Shared/constants/other"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"
import empty_collection_banner from "../../../../Admin/constants/empty_objects/empty_collection_banner"
import { ga_event_categories } from "../../../../Shared/constants/ga_constants"
import { get_ga_instance } from "../../../../../google_tracking"

export default {
  components: {
    Carousel,
    Slide,
    arrow,
    VLazyImage
  },
  props: {
    marketplace_collection_banners: {
      type: Array,
      required: false,
      default: () => []
    },
    admin_imported: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    show_mobile: {
      type: Boolean,
      required: false,
      default: false
    },
    guide_data: {
      type: Array | null,
      required: false,
      default: null
    }
  },
  data() {
    return {
      visible: false,
      scrolled_max: false,
      timeout: null,
      max_scroll: 1000,
      computed_banners_width: 0,
      default_image_src
    }
  },
  computed: {
    ...mapState(SHARED_STORE, [
      "is_mobile_resolution",
    ]),
    ...mapState(CLIENT_STORE, [
      "translations",
      "banners",
      "user_is_using_fast_connection",
      "project_styles",
      "products",
      "collections",
      "selected_language"
    ]),
    ...mapState(CONTENT_STORE, {
      admin_banners: "banners",
    }),
    ...mapState(PRODUCTS_STORE, [
      "imported_collections",
      "imported_products",
      "shopify_products",
      "shopify_collections",
    ]),
    ...mapState(CONFIGURATION_STORE, {
      admin_translations: "translations",
    }),
    ...mapState([
      "is_marketplace_environment",
    ]),
    show_empty_admin_preview() {
      if (this.guide_data && !this.guide_data.length) return false
      return this.admin_imported && this.admin_banners.length === 0
    },
    get_correct_entities() {
      return this.admin_imported ?
        [
          ...(this.shopify_products || []), ...(this.shopify_collections || []),
          ...(this.imported_collections || []), ...(this.imported_products || [])
        ] : [...this.products, ...this.collections]
    },
    admin_banner() {
      return ((this.admin_banners ? this.admin_banners.collection_banners : []) || []).filter(({ change_type }) => change_type !== deleted)
    },
    collection_banners() {
      if (this.guide_data) return this.guide_data
      if (this.marketplace_collection_banners.length) return this.marketplace_collection_banners

      if (this.admin_imported && !this.admin_banner.length) {
        const empty_collection = empty_collection_banner(
          null, null, null, null, null, "Default button",
          this.$translate("collection_banners.default_message"), this.$translate("collection_banners.default_title")
        )

        return [empty_collection, empty_collection]
      }

      return this.admin_imported ? this.admin_banner : this.banners.collection_banners
    },
    multiple_items() {
      return this.collection_banners.length > 2 && !this.is_mobile_device
    },
    correct_translations() {
      return this.admin_imported ? this.admin_translations : this.translations
    },
    decide_items_per_slide() {
      if (this.admin_imported && this.show_mobile) return [[300, 1]]

      return this.admin_imported && !this.show_mobile ? [[300, 2]] : [[300, 1], [650, 2]]
    }
  },
  watch: {
    visible(is_visible) {
      this.timeout = is_visible ?
        setInterval(() => this.check_and_update_scrolled_max(), 300) :
        (this.timeout && clearInterval(this.timeout))
    }
  },
  mounted() {
    this.timeout = setInterval(() => this.check_and_update_scrolled_max(), 300);
  },
  destroyed () {
    clearInterval(this.timeout)
  },
  methods: {
    scroll_content_to_end_or_front(move_to_end) {
      // If larger thant possible scroll, it defaults to max
      this.$refs.product_banners_wrapper.scrollLeft = move_to_end ? 0 : 50000
      this.max_scroll = this.$refs.product_banners_wrapper.scrollLeft
      this.scrolled_max = !move_to_end
    },
    check_and_update_scrolled_max() {
      if (this.$refs.product_banners_wrapper) {
        if (!this.computed_banners_width)
          this.computed_banners_width = Number(window.getComputedStyle(this.$refs.product_banners_wrapper).width.replace("px", ""))

        const banners_width = ((this.computed_banners_width / 2) - (this.admin_imported ? 20 : 0)) * this.collection_banners.length
        const user_scrolled_to_end = banners_width - (this.computed_banners_width + this.$refs.product_banners_wrapper.scrollLeft)

        this.scrolled_max = user_scrolled_to_end <= 0
      }
      else clearInterval(this.timeout)
    },
    transform_to_normal_text(value) {
      const found_entity = this.get_correct_entities.find(({ handle }) => handle === value)

      return found_entity ? get_correct_translation_value(found_entity, "title", [this.selected_language]) : ""
    },
    get_banner_route(direct_route, sub_category, category) {
      return this.is_marketplace_environment ?
        category : (direct_route || `/Products/${sub_category ? `${category}/${sub_category}` : category}`)
    },
    get_correct_image(img, mobile_image) {
      if (
        this.is_mobile_resolution ||
        !this.user_is_using_fast_connection
      ) return mobile_image || img || default_image_src

      return img || default_image_src
    },
    handle_link_click(e, category, sub_category) {
      if (this.admin_imported) {
        e.preventDefault()
        this.$emit("handle_click")
      }
      else {
        get_ga_instance().then((ga) => {
          ga.track(ga_event_categories.collection_banner, `${category}-${sub_category}`)
        })
      }
    }
  }
}
</script>

<style lang="scss">
@use "../../../../../styles/_global-constants" as *;
@use "../../../../../styles/element_loading.scss";

.product-collection-banner {
  width: 100%;
  margin: 50px 0;

  @media (max-width: $tablet) {
    margin: 80px 0 0;
  }

  &--admin {
    max-width: $maximum-width;
    margin: 20px auto 0;

    a {
      color: var(--background_light) !important;

      &:hover {
        color: var(--background_light) !important;
      }
    }
  }

  &__wrapper {
    position: relative;
  }

  &__demo-message {
    position: absolute;
    top: -10px;
    left: 5px;
    right: 5px;
    border-radius: $border-radius;
    height: calc(100% + 10px);
    background: $transparency-white-color;
    z-index: 1;
    text-align: center;

    p {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      margin: 0;
    }
  }

  h2 {
    padding: 0 10px;
    margin: 0 auto 30px;
    text-align: center;
    font-size: 24px !important;
    font-weight: bold;
    color: var(--background_heavy);
  }

  &__item {
    position: relative;
    min-height: 300px;
    padding: 0 15px;
    overflow: hidden;
    box-sizing: border-box;

    .product-collection-banner__background {
      left: 15px;
    }
    .product-collection-banner__gradient {
      left: 15px;
    }

    @media (max-width: $tablet--small) {
      padding: 0 5px !important;

      .product-collection-banner__gradient, .product-collection-banner__background {
        left: 5px !important;
      }
    }
  }

  &__title {
    font-weight: 500;
  }

  &__gradient {
    position: absolute;
    top: 0;
    width: calc(100% - 30px);
    overflow: hidden;
    height: 100%;
    border-radius: 6px;
    z-index: 0;
    opacity: 0.8;
    background: linear-gradient(314.09deg, var(--background_light) 0%, var(--background_heavy) 70%);

    @media (max-width: $tablet--small) {
      width: calc(100% - 10px);
    }
  }

  &__background {
    position: absolute;
    top: 0;
    width: calc(100% - 30px);
    border-radius: 6px;
    overflow: hidden;
    height: 100%;
    z-index: 0;

    @media (max-width: $tablet--small) {
      width: calc(100% - 10px);
    }

    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
    }
  }

  &__content {
    position: relative;
    min-height: 100%;
    padding: 20px 20px 50px;
    border-radius: 6px;
    overflow: hidden;
    color: var(--item_name_color);
    box-sizing: border-box;

    h3 {
      padding: 0;
      margin: 0 0 20px;
      color: var(--item_name_color) !important;
    }

    p {
      margin: 0;
      font-size: 15px;
      font-weight: 300;
    }
  }

  &__link {
    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 16px;
    font-weight: bold;
    color: var(--background_light) !important;

    svg {
      display: inline-block;
      height: 11px;
      vertical-align: middle;
      fill: var(--background_light) !important;
    }
  }

  .VueCarousel-dot {
    position: relative;
    background-color: transparent !important;

    &--active:after {
      background-color: var(--font_heavy) !important;
    }

    &:after {
      position: absolute;
      width: 17px;
      height: 17px;
      top: 7px;
      left: 7px;
      border: 2px solid var(--font_heavy);
      border-radius: 100%;
      box-sizing: border-box;
      content: "";
    }
  }

  .element-loading {
    border-radius: 6px;
    transform: scale3d(0.95, 1, 1);
  }
}

</style>
